<script>
import { mapState } from 'vuex';
import calendarRules from '@/enums/calendarRules';
import LogService from '@/services/LogService';
import common from '@/mixins/common';
import Period from './periodVacationComponent.vue';

export default {
  extends: Period,
  name: 'PeriodAlt',
  mixins: [common],
  props: {
    calendarRules: {
      type: String,
      default: calendarRules.default,
    },
  },
  data: () => ({
    zeroTime: {
      h: 0,
      m: 0,
      s: 0,
      ms: 0,
    },
  }),
  computed: {
    ...mapState(['strings']),
    validation() {
      return this.validationRules;
    },
    disabledStart() {
      const { validation } = this;
      const start_date = this.startDate;
      const end_date = this.endDate;
      LogService.log('disableStart', start_date, end_date, validation);
      const res = this.updateStartAndEndDate(start_date, end_date, validation);
      return ({
        to: res.disabledStartTo,
        from: res.disabledStartFrom,
      });
    },
    disabledEnd() {
      const { validation } = this;
      const start_date = this.startDate;
      const end_date = this.endDate;
      const res = this.updateStartAndEndDate(start_date, end_date, validation);
      return ({
        to: res.disabledEndTo,
        from: res.disabledEndFrom,
      });
    },
    datesComputed() {
      let dates = [];
      const startDate = this.$moment(this.startDate);
      const endDate = this.$moment(this.endDate);
      if (!startDate.isValid() || !endDate.isValid()) return dates;
      dates = this.$moment.range(startDate, endDate).by('days');
      dates = Array.from(dates).map((d) => d.toDate());
      return dates;
    },
    startInputDisabled() {
      return false;
    },
    endInputDisabled() {
      return false;
    },
    startDateErrors() {
      return [];
    },
  },
  watch: {
    defaultStartDate: {
      immediate: true,
      handler(startDate) {
        LogService.log('defaultStartDate changed', startDate);
        // if (!startDate) return;
        this.onChangeStartDate(startDate);
      },
    },
    defaultEndDate: {
      immediate: true,
      handler(endDate) {
        // if (!endDate) return;
        this.setEndDate(endDate);
      },
    },
    datesComputed: {
      handler(datesComputed) {
        this.$emit('update:dates', datesComputed);
      },
    },
  },
  methods: {
    updateStartAndEndDate(start_date, end_date, validation) {
      if (this.calendarRules === calendarRules.derTourPromotion) {
        return this.calcRestrictionsFromStartAndEndDate({
          start_date,
          end_date,
          start_calendar: '2023-09-01',
          end_calendar: '2023-11-30',
          min_contract_start_postponement_less_29:
          validation.min_contract_start_postponement_less_29,
          max_date_range: validation.max_date_range,
        });
      }
      LogService.log('updateStartAndEndDate', { validation, start_date, end_date });

      return this.calcRestrictions({ validation, start_date, end_date });
    },
    /**
     * set startDate and update endDate
     */
    onChangeStartDate(newStartDate) {
      this.setStartDate(newStartDate);
    },
    setStartDate(start_date) {
      // set start date
      const { startDate, endDate, large } = this.validateDates({ start_date });
      /** show less message */
      const show = large
        && this.$moment.today().add(this.validation.min_contract_start_postponement, 'days').isAfter(start_date === this.defaultStartDate ? startDate : start_date);
      if (show) {
        this.showPopup();
      }
      this.$emit('update:startDate', startDate);
      this.$emit('update:endDate', endDate);
      return startDate;
    },
    setEndDate(end_date) {
      // set end date
      const { startDate, endDate, large } = this.validateDates({ end_date });
      /** show less message */
      const show = large
        && this.$moment.today().add(this.validation.min_contract_start_postponement, 'days').isAfter(this.startDate === undefined ? startDate : this.startDate);
      if (show) {
        this.showPopup();
      }
      this.$emit('update:startDate', startDate);
      this.$emit('update:endDate', endDate);
    },
    validateDates({ start_date, end_date }) {
      const { validation } = this;
      const res = this.updateStartAndEndDate(
        start_date || this.startDate || this.defaultStartDate,
        end_date || this.endDate || this.defaultEndDate,
        validation,
      );
      LogService.log('possible values', start_date, this.startDate, this.defaultStartDate,
        end_date, this.endDate, this.defaultEndDate);
      LogService.log('validateDates', res,
        { startDate: res.startDate, endDate: res.endDate, large: res.large });
      return { startDate: res.startDate, endDate: res.endDate, large: res.large };
    },
    /**
     * @param args<Object> - {
     *   validation<Object>,
     *   start_date,
     *   end_date,
     *   dir_end_date<String>: 'up' || 'down'
     * }
     */
    calcRestrictions(args) {
      const start_date = args.start_date ? this.$moment(args.start_date) : undefined;
      const end_date = args.end_date ? this.$moment(args.end_date) : undefined;
      const {
        min_contract_start_postponement_less_29,
        min_contract_start_postponement,
        max_contract_start_postponement,
        max_date_range,
        min_contract_length,
      } = args.validation || {};

      /** has validation params? */
      if (
        !min_contract_start_postponement_less_29
        || !min_contract_start_postponement
        || !max_contract_start_postponement
        || !max_date_range
        || !min_contract_length
      ) return {};

      let result = {};

      /** set default start & end limits */
      const calcLimits = (min_contract_start = min_contract_start_postponement_less_29) => {
        const disabledStartTo = this.$moment.today().add(min_contract_start + 1, 'days');
        const disabledStartFrom = this.$moment.today().add(max_contract_start_postponement, 'days');
        const tempStartDate = start_date
          ? this.$moment.min(this.$moment.max(start_date, disabledStartTo), disabledStartFrom)
          : disabledStartTo;
        const disabledEndTo = tempStartDate.clone().add(min_contract_length - 1, 'days');
        const disabledEndFrom = tempStartDate.clone().add(max_date_range - 1, 'days');

        LogService.log('disableEndFrom', disabledEndFrom);
        result = {
          ...result,
          disabledStartTo,
          disabledStartFrom,
          disabledEndTo,
          disabledEndFrom,
        };
      };
      calcLimits();

      /** set date values */
      const setDateValues = () => {
        const startDate = start_date
          ? this.$moment.min(
            this.$moment.max(result.disabledStartTo, start_date),
            result.disabledStartFrom,
          )
          : result.disabledStartTo;
        const endDate = end_date
          ? this.$moment.max(
            this.$moment.min(result.disabledEndFrom, end_date),
            result.disabledEndTo,
            startDate,
          )
          : result.disabledEndTo;

        LogService.log('end_date', result.disabledEndFrom, end_date, result.disabledEndTo, startDate);
        LogService.log('start_date', result.disabledStartTo, start_date, result.disabledStartFrom);
        result = {
          ...result,
          startDate,
          endDate,
        };
      };
      setDateValues();

      /** check range for less */
      const less = result.endDate.diff(result.startDate, 'days') + 1 <= 28;

      /** set 'default' given the 'less' */
      const min_start = less
        ? min_contract_start_postponement_less_29
        : min_contract_start_postponement;

      /** recalc limits */
      calcLimits(min_start);

      /** correct date values */
      setDateValues();

      LogService.log('calcRestrictions', {
        disabledStartTo: result.disabledStartTo.toDate(),
        disabledStartFrom: result.disabledStartFrom.toDate(),
        disabledEndTo: result.disabledEndTo.toDate(),
        disabledEndFrom: result.disabledEndFrom.toDate(),
        startDate: start_date ? result.startDate.toDate() : start_date,
        endDate: end_date ? result.endDate.toDate() : end_date,
        large: !less,
      });

      this.defaultValue = result.startDate.toDate();
      return {
        disabledStartTo: result.disabledStartTo.toDate(),
        disabledStartFrom: result.disabledStartFrom.toDate(),
        disabledEndTo: result.disabledEndTo.toDate(),
        disabledEndFrom: result.disabledEndFrom.toDate(),
        startDate: start_date ? result.startDate.toDate() : start_date,
        endDate: end_date ? result.endDate.toDate() : end_date,
        large: !less,
      };
    },
    /**
     * @param args<Object> - {
     *   start_date,
     *   end_date,
     *   start_calendar,
     *   end_calendar,
     *   min_contract_start_postponement,
     *   max_date_range
     * }
     */
    calcRestrictionsFromStartAndEndDate(args) {
      const start_date = args.start_date ? this.$moment(args.start_date) : undefined;
      const end_date = args.end_date ? this.$moment(args.end_date) : undefined;
      const start_calendar = args.start_calendar ? this.$moment(args.start_calendar) : undefined;
      const end_calendar = args.end_calendar ? this.$moment(args.end_calendar) : undefined;
      let result = {};

      /** set default start & end limits */
      const calcLimits = (min_contract_start = args.min_contract_start_postponement_less_29) => {
        const disabledStartTo = this.$moment.max(
          this.$moment.today().add(min_contract_start + 1, 'days'),
          start_calendar,
        );
        const disabledStartFrom = end_calendar;
        const tempStartDate = start_date
          ? this.$moment.min(this.$moment.max(start_date, disabledStartTo), disabledStartFrom)
          : disabledStartTo;
        const disabledEndTo = tempStartDate.clone().add(args.min_contract_length - 1, 'days');
        const disabledEndFrom = tempStartDate.clone().add(args.max_date_range - 1, 'days');

        result = {
          ...result,
          disabledStartTo,
          disabledStartFrom,
          disabledEndTo,
          disabledEndFrom,
        };
      };
      calcLimits();

      /** set date values */
      const setDateValues = () => {
        const startDate = start_date
          ? this.$moment.min(
            this.$moment.max(result.disabledStartTo, start_date),
            result.disabledStartFrom,
          )
          : result.disabledStartTo;
        const endDate = end_date
          ? this.$moment.max(
            this.$moment.min(result.disabledEndFrom, end_date),
            result.disabledEndTo,
            startDate,
          )
          : result.disabledEndTo;

        result = {
          ...result,
          startDate,
          endDate,
        };
      };
      setDateValues();

      /** check range for less */
      const less = result.endDate.diff(result.startDate, 'days') + 1 <= 28;

      return {
        disabledStartTo: result.disabledStartTo.toDate(),
        disabledStartFrom: result.disabledStartFrom.toDate(),
        disabledEndTo: result.disabledEndTo.toDate(),
        disabledEndFrom: result.disabledEndFrom.toDate(),
        startDate: result.startDate.toDate(),
        endDate: result.endDate.toDate(),
        large: !less,
      };
    },
    showPopup() {
      // show the popup
      const message = this.format(
        this.strings.commonApp?.calendar?.error_more_than_postponement,
        this.validation.min_contract_start_postponement,
        Number.parseInt(this.validation.min_contract_start_postponement, 10) === 1
          ? this.strings.commonApp?.day : this.strings.commonApp?.days,
        this.validation.min_contract_start_postponement,
      );
      const modal = {
        id: 'wet-period-alt-postponement-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-dates-postponement-ok',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
  },
};
</script>

<style>

</style>
